import { get } from 'aws-amplify/api';

const apiName = 'meinvitanAPI';

export const getInviteById = async (slug) => {
  try {
    const path = `/invite/${slug}`;
    const response = await get({
        apiName,
        path,
        
      }).response;
    
    return response.body.json();
  } catch (error) {
    console.error('Error fetching data from API:', error);
    throw error;
  }
};