import React, { useEffect, useState } from "react";
import Components from "./components.js";
import { useParams } from "react-router-dom";
import { getInviteById } from "./services/api.service.js";

function Renderer() {
  const [invitation, setinvitation] = useState(null);
  const [notFound, setnotFound] = useState(null);
  

  let params = useParams();

  useEffect(() => {
    let fetchInvite = async ()=>{
      try {
        var response = await getInviteById(params.code);
        let invitation = response;
        if (invitation != null) {
          setinvitation(invitation);
        } else {
          setnotFound(true);
        }
      } catch (error) {
        setnotFound(true);
      }
    }

    fetchInvite();
   
  }, [params]);

  return (
    <div className="App">
      {invitation === null && notFound === null
        ? "loading"
        : notFound === true
        ? "Not found"
        : Components(invitation)}
    </div>
  );
}

export default Renderer;
