import React from "react";
import "./home.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function Home() {

  return (
    <div id="landing">

      <nav className="navbar fixed-top navbar-expand-lg navbar-light bg-primary">
        <div className="container">
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggler" aria-controls="navbarToggler" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>

          <a className="navbar-brand text-logo h1" href="/">meinvitan.com</a>

          <div className="collapse navbar-collapse" id="navbarToggler">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a className="nav-link active" href="#home" >Inicio</a>
              </li>
              <li className="nav-item">
                <a className="nav-link active" href="#diseno" >Diseños</a>
              </li>
              <li className="nav-item">
                <a className="nav-link active" href="#precio" >Paquetes</a>
              </li>
              <li className="nav-item">
                <a className="nav-link active" href="#pregunta" >Preguntas</a>
              </li>
              {/* <li className="nav-item">
                <a className="nav-link active" href="#contacto" >Contacto</a>
              </li> */}
            </ul>
          </div>
        </div>
      </nav>

      <div id="home" className="container-fluid pb-5">
        <div className="row align-items-center m-0">

          <div className="col-md-4 p-2 pt-3 offset-md-2">
            <h1 id="title">El poder de tu invitación</h1>
            <p id="title-text">Deja que tus seres queridos tengan una buena experiencia desde la invitación.</p>
            <div className="d-none d-md-block">
              <a id="action-button" className="btn btn-dark text-white" href="#diseno">Ver Diseños</a>
            </div>
          </div>

          <div className="col-md-6 text-center">
            <div id="parallax-image">
              <img id="home-image" className="image-responsive" src="assets/img/home.png" alt="Invitaciones ejemplo boda, bautizo, fiesta, baby shower, quince años" />
            </div>
          </div>

          <div className="row text-center d-block d-sm-none">
            <div className="col">
              <a id="action-button" className="btn btn-dark text-white" href="#diseno">Ver Diseños</a>
            </div>
          </div>
        </div>
      </div>

      <div className="container pt-5 pb-5" id="diseno">
        <div className="row text-center">
          <h1>Categorías</h1>
        </div>
        <div className="row text-center row-cols-2 row-cols-md-4">
          <div className="col">
            <div className="border category-thumb">
              <h4>Bodas</h4>
              <a href="https://m.me/meinvitan" target="_blank" rel="noreferrer">
                <img src="assets/img/illustrations/weddingtile.jpg" alt="" />
              </a>
              
            </div>
          </div>
          <div className="col">
            <div className="border category-thumb">
              <h4>XV Años</h4>
              <a href="https://m.me/meinvitan" target="_blank" rel="noreferrer">
                <img src="assets/img/illustrations/quinceaneratile.jpg" alt="" />
              </a>
            </div>
          </div>
          <div className="col">
            <div className="border category-thumb">
              <h4>Cumpleaños</h4>
              <a href="https://m.me/meinvitan" target="_blank" rel="noreferrer">
                <img src="assets/img/illustrations/cumpleanostile.jpg" alt="" />
              </a>
            </div>
          </div>
          <div className="col">
            <div className="border category-thumb">
              <h4>Bautizo</h4>
              <a href="https://m.me/meinvitan" target="_blank" rel="noreferrer">
                <img src="assets/img/illustrations/bautizo.jpg" alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="container pt-5 pb-5" id="precio">
        <div className="row text-center">
          <h1>Paquetes</h1>
        </div>
        <div className="row text-center row-cols-1 row-cols-md-2 row-cols-lg-2">
          <div className="col">
            <div className="card mb-4 rounded-3 shadow-sm">
              <div className="card-header py-3">
                <h4 className="my-0 fw-normal">Básico</h4>
              </div>
              <div className="card-body">
                <h1 className="card-title pricing-card-title">$199.00 MNX</h1>
                <ul className="list-unstyled mt-3 mb-4">
                  <li>Diseño del catálogo</li>
                  <li>Información del evento</li>
                  <li>&nbsp;</li>
                  <li>&nbsp;</li>
                </ul>
                <a href="https://m.me/meinvitan" target="_blank" rel="noreferrer" className="w-100 btn btn-lg btn-outline-info text-dark">Solicitar</a>
              </div>
            </div>

          </div>
          <div className="col">
            <div className="card mb-4 rounded-3 shadow-sm">
              <div className="card-header py-3">
                <h4 className="my-0 fw-normal">Completo</h4>
              </div>
              <div className="card-body">
                <h1 className="card-title pricing-card-title">$499.00 MNX</h1>
                <ul className="list-unstyled mt-3 mb-4">
                  <li>Diseño personalizado</li>
                  <li>Información del evento</li>
                  <li>Itinerario con mapa</li>
                  <li>Confirmación por WhatsApp</li>
                </ul>
                <a href="https://m.me/meinvitan" target="_blank" rel="noreferrer"  className="w-100 btn btn-lg btn-outline-info text-dark">Solicitar</a>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div className="container pt-5 pb-5" id="pregunta">
        <div className="row text-center">
          <h1>Preguntas Frecuentes</h1>
        </div>
        <div className="row">
          <div className="accordion accordion-flush bsb-accordion-flush bsb-accordion-icon-caret bsb-accordion-icon-position-before" id="accordionExample">
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                  ¿Cómo puedo pagar?
                </button>
              </h2>
              <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  Aceptamos pagos con transferencia, una vez validada tu transferencia se procede a publicar la invitación.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingTwo">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  ¿En cuánto tiempo estará disponible mi invitación?
                </button>
              </h2>
              <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  Una vez que se tengan todos los datos disponibles para la invitación, incluido el diseño de la invitación estará disponible en máximo una semana.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                  ¿Hay más diseños?
                </button>
              </h2>
              <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  Estamos construyendo una serie de diseños, si ninguno es de tu agrado se puede generar uno nuevo a partir de pláticas con el diseñador y las necesidades de tu evento.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="container-fluid" id="footer">
        <section className=" text-center p-4 border-bottom">
          <span>Búscanos en nuestras redes sociales:</span>
          <div>
            <a href="https://facebook.com/meinvitan" className="btn btn-outline-primary m-1 link-primary" target="_blank" rel="noreferrer">
              <FontAwesomeIcon icon={['fab', 'facebook']} />
            </a>
            <a href="https://instagram.com/meinvitan" className="btn btn-outline-primary m-1 link-primary" target="_blank" rel="noreferrer">
              <FontAwesomeIcon icon={['fab', 'instagram']} />
            </a>
          </div>

        </section>

        <div className="container p-2">
          <div className="row align-items-center">

            <div className="col px-2">
              <h4 className="text-logo text-justify">meinvitan.com</h4>
              <p className="lh-sm">Deja que tus seres queridos tengan una buena experiencia desde la invitación.</p>
            </div>

            <div className="col px-2">
              <h4>Enlaces</h4>
              <ul id="footer-links" className="lh-sm">
                <li className="nav-item">
                  <a className="nav-link" href="#home" >Inicio</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#diseno" >Diseños</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#precio" >Paquetes</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#pregunta" >Preguntas</a>
                </li>
                {/* <li className="nav-item">
                  <a className="nav-link" href="#contacto" >Contacto</a>
                </li> */}
              </ul>
            </div>

            <div className="col border-start">
              <h4>Contacto</h4>
              <address>
                <strong>Me Invitan</strong><br />
                Av. Montaña Monarca<br />
                Morelia, Michoacán, C.P. 58350<br />
                {/* <abbr title="Phone">P:</abbr> (443) 362-3685 <br /> */}
                <abbr title="Email">E:</abbr> contacto.meinvitan@gmail.com
              </address>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default Home;
